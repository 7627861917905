import { AuthConfig, OAuthModuleConfig } from 'angular-oauth2-oidc';

import { environment } from '../../../environments/environment';

export const authConfig: AuthConfig = {
  // Your Auth0 app's domain
  // Important: Don't forget to start with https://
  //  AND the trailing slash!
  issuer: environment.auth0Domain,

  // The app's clientId configured in Auth0
  clientId: environment.auth0ClientID,

  // The app's redirectUri configured in Auth0
  redirectUri: window.location.origin + '/login',

  // Scopes ("rights") the Angular application wants get delegated
  scope: 'openid profile email offline_access',

  // Using Authorization Code Flow
  // (PKCE is activated by default for authorization code flow)
  responseType: 'code',

  // Your Auth0 account's logout url
  // Derive it from your application's domain
  //logoutUrl: 'https://dev-j8mfisdys2qxxop3.us.auth0.com/v2/logout',
  logoutUrl: window.location.origin + '/login',

  customQueryParams: {
    // API identifier configured in Auth0
    audience: environment.auth0Audience,

    prompt: 'login', // login, consent
  },
};

export const authModuleConfig: OAuthModuleConfig = {
  // Inject "Authorization: Bearer ..." header for these APIs:
  resourceServer: {
    sendAccessToken: true,
    allowedUrls: ['https://www.angular.at/api/'],
  },
};
