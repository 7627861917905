import { NgModule } from '@angular/core';
import { APP_BASE_HREF } from "@angular/common";
import { RouterModule, Routes } from '@angular/router';
import { EmptyRouteComponent } from "./modules/empty-route/empty-route.component";

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/login/login.module').then((module) => module.LoginModule),
  },
  {
    path: "**",
    component: EmptyRouteComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [{ provide: APP_BASE_HREF, useValue: "/" }],
})
export class AppRoutingModule {}
